.pricing-card {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 30px;
  transition: transform 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-5px);
}

.pricing-card h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.pricing-card p {
  font-size: 18px;
  margin-bottom: 20px;
}

.pricing-card ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 20px;
}

.pricing-card ul li {
  margin-bottom: 10px;
}

.pricing-card button {
  width: 100%;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.pricing-card button:hover {
  background-color: #007bff;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.service-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  text-align: center;
  margin: 10px;
  padding: 20px;
}

.service-icon {
  font-size: 3em;
  margin-bottom: 10px;
  color: #4CAF50; /* Change the color to match your theme */
}

.service-type {
  font-size: 1.5em;
  margin: 10px 0;
}

.service-points {
  list-style-type: none;
  padding: 0;
}

.service-points li {
  padding: 5px 0;
}